<div class="wt-search">
  <mat-icon class="wt-search__icon" fontIcon="search"></mat-icon>
  <input
    class="wt-search__input"
    [(ngModel)]="searchInputValue"
    [placeholder]="'SEARCH.INPUT_PLACEHOLDER' | translate"
    (focus)="activateDropdown()"
    (click)="activateDropdown()"
    autocomplete="off"
    type="search"
    id="search"
    #searchElm />
</div>

<div (clickOutside)="closeDropdown()" [ignoreElements]="[searchElm]" *ngIf="showDropdown$ | async">
  <div class="wt-search__dropdown" *ngIf="!!searchInputValue">
    Search component here
  </div>
</div>