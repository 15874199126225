import type { ComponentType } from '@angular/cdk/portal';
import type { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

interface OverlayDefn {
  config: MatDialogConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  load: () => Promise<ComponentType<any>>;
}
export const overlays: { [name: string]: OverlayDefn; } = {
  screener: {
    config: {},
    load: () => import('../../screener/screener.component').then(m => m.ScreenerComponent),
  },
  sectors: {
    config: {},
    load: () => import('../../sectors/sectors.component').then(m => m.SectorsComponent),
  },
  watchlists: {
    config: {},
    load: () => import('../../watchlists/watchlists.component').then(m => m.WatchlistsComponent),
  },
} as const;
export type OverlayName = keyof typeof overlays;
