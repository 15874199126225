<div class="wt-flex-row wt-flex-align-middle">
  <div class="wt-flex-column wt-flex-shrink">
    <img class="app-logo" src="assets/logo.svg" alt="">
  </div>

  <div class="wt-flex-column wt-flex-shrink">
    <wt-app-search></wt-app-search>
  </div>

  <nav class="wt-flex-column wt-flex-shrink wt-header-addons">
    <ul class="wt-header-addons__list">
      <li class="wt-header-addons__item">
        <button class="wt-header-addons__button" (click)="openOverlay('screener')">{{ 'HEADER.ADDONS.SCREENER' |
          translate }}</button>
      </li>
      <li class="wt-header-addons__item">
        <button class="wt-header-addons__button" (click)="openOverlay('sectors')">{{ 'HEADER.ADDONS.SECTORS' | translate
          }}</button>
      </li>
      <li class="wt-header-addons__item">
        <button class="wt-header-addons__button" (click)="openOverlay('watchlists')">{{ 'HEADER.ADDONS.WATCHLISTS' |
          translate }}</button>
      </li>
    </ul>
  </nav>
</div>