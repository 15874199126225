import { ChangeDetectionStrategy, Component, type ElementRef, ViewChild, type OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'wt-app-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnDestroy {

  private readonly ngUnsubscribe = new Subject<void>();

  private readonly showDropdown = new BehaviorSubject<boolean>(false);
  readonly showDropdown$ = this.showDropdown.asObservable();

  @ViewChild('searchElm', { 'static': false }) searchElm!: ElementRef<HTMLInputElement>;

  readonly searchQuery = new BehaviorSubject<string | undefined>(undefined);
  readonly searchQuery$ = this.searchQuery.asObservable();
  private _searchInputValue = '';

  get searchInputValue(): string {
    return this._searchInputValue;
  }
  set searchInputValue(value: string) {
    this._searchInputValue = value;
    this.searchQuery.next(value);
  }

  clearSearchInput(): void {
    this.searchElm.nativeElement.blur();
    this.searchInputValue = '';
  }

  closeDropdown(): void {
    this.showDropdown.next(false);
  }

  activateDropdown(): void {
    this.showDropdown.next(true);
  }

  ngOnDestroy(): void {
    this.showDropdown.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
