import { LogLevel } from '@vwd/ngx-logging';

const hostEnv = environmentFromHost(location.hostname);

export const sharedEnv = {
  ...hostEnv,
  keycloakApiUrl: hostEnv.gateway + '/api/idm/', // FIXME: should we use /api/... for localhost?
  logging: {
    // environment.local.ts
    // environment.ts
    localhost: {
      'default': LogLevel.log,
    },
    test: {
      'default': LogLevel.info,
    },
    uat: {
      'default': LogLevel.warning,
    },
    // environment.prod.ts
    production: {
      'default': LogLevel.warning,
    },
    staging: {
      'default': LogLevel.warning,
    },
  },
};

interface HostEnvironment {
  realm: string | { default: string; };
  allowRealmViaUrl: boolean;
  envName: 'localhost' | 'test' | 'uat' | 'staging' | 'production';
  gateway: string;
  keycloakUrl: string;
}

function environmentFromHost(hostname: string): HostEnvironment {
  // [<realm>.]<product>[-<env>].<region>.infrontservices.com
  const hostnamePattern = /^(?:([a-z0-9]+)\.)?(\w+)(?:-(test|uat|staging))?\.(?:[a-z]+)\.infrontservices\.com$/;

  const match = hostnamePattern.exec(hostname);
  if (match) {
    const [, realm, app, env, region] = match;
    const cloudInstance = !env || env === 'staging' ? 'cloud' : 'cloud-uat'; // we don't support cloud-test, only cloud-uat
    const cloudRegion = region || 'eu';
    return {
      realm: realm || 'infront',
      allowRealmViaUrl: false,
      envName: (env || 'production') as 'test' | 'uat' | 'staging' | 'production',
      gateway: `https://${realm}.${cloudInstance}.${cloudRegion}.infrontservices.com`,
      keycloakUrl: `https://${realm}.${cloudInstance}.${cloudRegion}.infrontservices.com/auth`,
    };
  }

  return {
    realm: 'infront', // localhost and Azure test URLs allow ?realm=
    allowRealmViaUrl: true,
    envName: hostname === 'localhost' ? hostname : 'test',
    gateway: 'https://gateway.cloud-uat.eu.infrontservices.com',
    keycloakUrl: 'https://idm.cloud-uat.eu.infrontservices.com/auth',
  };
}
