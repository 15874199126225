import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SearchModule } from '../search/search.module';
import { HeaderComponent } from './header/header.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SplashComponent } from './splash/splash.component';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

@NgModule({
  exports: [
    MainLayoutComponent,
  ],
  declarations: [
    HeaderComponent,
    MainLayoutComponent,
    SplashComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,

    I18nTranslateModule,

    SearchModule,
  ]
})
export class LayoutModule { }
