import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { type OverlayName, overlays } from './overlays';

@Component({
  selector: 'wt-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

  private dialog = inject(MatDialog);

  async openOverlay(name: OverlayName): Promise<void> {
    const overlay = overlays[name];
    const componentType = await overlay.load();
    this.dialog.open(componentType, overlay.config);
  }
}
