import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Infront } from '@infront/sdk';
import { MFE_PREFS_TOKEN, initHost, initMFE, provideNgServices } from '@vwd/microfrontend-core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

initMFE({
  // Determine MFE library & API calls setup (not Keycloak)
  basePath: environment.gateway + '/lib/micro-frontend/',
});

// Toolkit in WT5 must be treated as production release!
// kBuildType release required for countryflags on local WT5
Infront.LibraryConstants.kBuildType = 'release';

initHost({
  name: 'web-trader-client', // TODO: change
  auth: {
    localeSupport: 'split-iso',
    loginRequired: true,
    realm: environment.realm,
    allowRealmViaURL: false,
    idmURL: environment.keycloakUrl,
    httpAddBearerFor: ['/api/*', '*.infrontfinance.com', '*.infrontservices.com', 'https://webclientservices.azurewebsites.net/*'],
    tokenStorage: 'localStorage',
    keycloakInitOptions: {
      useNonce: true,
      enableLogging: false,
      silentCheckSsoRedirectUri: '/assets/silent-check-sso.html',
    },
  },
  deps: [MFE_PREFS_TOKEN],
  lang: ['en-GB']
}).then(host => {
  return platformBrowserDynamic(provideNgServices(host))
    .bootstrapModule(AppModule);
}).catch((err: unknown) => console.error(err));
